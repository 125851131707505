import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SwiperCore, { Autoplay, FreeMode, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/free-mode';

import './index.css';

SwiperCore.use([FreeMode, Navigation, Autoplay]);

const CarouselV2 = React.memo(function CarouselV2(props) {
  const swiperRef = React.useRef(null);

  const settings = {
    /* slidesPerColumn: isMobile ? 3 : 2,
		slidesPerColumnFill: 'row', */
    slidesPerView: 2,
    breakpoints: {
      768: {
        slidesPerView: 6,
      },
    },

    centeredSlides: false,
    freeMode: true,
    loop: true,
    autoplay: {
      delay: 0,
      waitForTransition: false,
      disableOnInteraction: false,
      pauseOnMouseEnter: false,
    },
    speed: 5000,
    allowTouchMove: false,
  };
  return (
    <div className="pointer-events-none">
      <Swiper {...settings} ref={swiperRef}>
        {props.data.map((val, idx) => {
          return (
            <SwiperSlide
              key={`${val.name}${idx + 1}`}
              className=" mx-2"
              style={{ height: '190px' }}
            >
              <Card
                nestedData={val}
                dark={props.dark}
                gradient={props.gradient}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
});

export default CarouselV2;

function Card(props) {
  const dark = props.dark;
  const gradient = props.gradient;
  const companyLogo = getImage(props.nestedData.companyLogo.localFile);
  const profileImage = getImage(props.nestedData.profileImage.localFile);

  const getCardClass = () => {
    let className =
      'flex h-full flex-col items-center justify-center overflow-hidden p-2 pt-4';

    if (gradient) {
      className = className.concat(
        ' border-gradient gradient-card-light rounded-[30px]',
      );
    } else if (dark) {
      className = className.concat(' bg-v5-green-100');
    } else {
      className = className.concat(' bg-v5-neutral-200');
    }

    return className;
  };

  return (
    <div className={getCardClass()} style={{ borderRadius: '20px' }}>
      <div
        style={{ maxHeight: '100%', height: '50px', width: '50px' }}
        className="mb-3"
      >
        <GatsbyImage
          loading="lazy"
          image={profileImage}
          alt={'profile'}
          style={{ maxHeight: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>

      <h6
        className={`text-center  font-manrope font-extrabold ${
          gradient
            ? 'text-v5-green-500'
            : !dark
            ? 'text-black'
            : 'text-v5-green-400'
        } `}
      >
        {props.nestedData.name}
      </h6>
      <p
        className={`text-center font-light ${
          gradient
            ? 'text-v5-neutral-500'
            : !dark
            ? 'text-v5-neutral-400'
            : 'text-v5-neutral-500'
        }`}
      >
        is in
      </p>
      <div
        className="flex items-center justify-center"
        style={{
          maxHeight: '100%',
          height: '50px',
          width: '120px',
          // filter: 'brightness(10%)',
        }}
      >
        <GatsbyImage
          loading="lazy"
          image={companyLogo}
          alt={'company'}
          style={{ maxHeight: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
          {...(gradient && { className: 'filter brightness-50' })}
        />
      </div>
    </div>
  );
}
